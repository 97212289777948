<template>
	<div class="kanban" id='kanban'>
		<div class="header">
			<div class="title1">
				<img src="../../../../public/img/image/logobj.png"
					style="margin-top: 7%;margin-left: 6%;height: 50px;object-fit: cover;" />
			</div>
			<div class="title2">
				LPA 看 板
			</div>
			<div class="title3">
				<div style="margin-right: 10%;display: flex;align-items: center;justify-content: right;">
					<img src="../../../../public/img/image/calendar.png" style="margin-right: 10px;" />
					{{time}}
				</div>
			</div>
		</div>
		<div class="mainstay">
			<div class="row1">
				<div class="subtitle">
					<div
						style="width: 250px;height: 100%;display: flex;align-items: center;justify-content: center;position: relative;">
						<img src="../../../../public/img/image/luminescence1.png" style="height: 90%;width: 100%;" />
						<span style="position: absolute;">本月统计</span>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div style="width: 100%;height: 50px;position: absolute;">
							<img src="../../../../public/img/image/tittle1.png" style="width: 100%;" />
							<!-- <div class="icon-outer">
								<div class="icon-inner">
									
								</div>
							</div> -->
						</div>

						<div class="frame" id="DepartmentalPlanCompletionRate">

						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div style="width: 100%;height: 50px;position: absolute;">
							<img src="../../../../public/img/image/tittle1.png" style="width: 100%;" />
						</div>
						<div class="frame" id="DepartmentalCloseRate">

						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div style="width: 100%;height: 50px;position: absolute;">
							<img src="../../../../public/img/image/tittle1.png" style="width: 100%;" />
						</div>
						<div class="frame" id="DepartmentalConformRate">

						</div>
					</div>
				</div>
			</div>
			<div class="row2">
				<div class="subtitle">

				</div>
				<div class="column3">
					<div class="frame">
						<div style="width: 100%;height: 28%;position: relative;">
							<div style="width: 100%;height: 50px;position: absolute;">
								<img src="../../../../public/img/image/tittle2.png" style="width: 100%;" />
							</div>
							<div style="position: absolute;left: 52px;top: 6px;font-size: 20px;color: #fff">
								审核计划
							</div>
							<div style="position: absolute;right: 110px;top: 6px;font-size: 20px;color: #fff;cursor: pointer;"
								@click="AuditTaskView()">
								More
								<img src="../../../../public/img/image/more.png" />
							</div>
						</div>
						<div class="list">
							<div style="width: 25%;height: 50%;padding: 2px 7px;margin-top: 5px;"
								v-for="(item,index) in AuditPlanList" @click="AuditPlanView(item)">
								<div class="planBox">
									<div
										style="width: 100%;height: 25%;padding-left: 15px;display: flex;align-items: center;justify-content: left;color: #c8c8c8;font-size: 12px;">
										{{item.fomartDateTime}}
									</div>
									<div
										style="width: 90%;height: 40%;padding-left: 15px;font-size: 15px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
										{{item.name}}
									</div>
									<div style="width: 100%;height: 35%;display: flex;font-size: 12px;">
										<div
											style="width: 50%;height: 100%;padding-left: 15px;display: flex;align-items: center;justify-content: left;">
											{{item.inspectorName}}
										</div>
										<div
											style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: end;padding-right: 15px;">
											<div class="state1"
												v-if="item.auditTaskStateName != '已完成' && item.auditTaskStateName != '未完成'">
												{{item.auditTaskStateName}}
											</div>
											<div class="state2" v-if="item.auditTaskStateName === '已完成'">
												{{item.auditTaskStateName}}
											</div>
											<div class="state3" v-if="item.auditTaskStateName === '未完成'">
												{{item.auditTaskStateName}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <table style="width: 94%;height: 15%;margin-left: 3%;margin-top: 1%;background-color: #253f8c;">
								<tbody>
									<tr style="text-align: center;color: #fff">
										<th style="border: 1px solid #fff;width: 20%;">日期</th>
										<th style="border: 1px solid #fff;width: 60%;">名称</th>
										<th style="border: 1px solid #fff;width: 10%;">审核人</th>
										<th style="border: 1px solid #fff;width: 10%;">状态</th>
									</tr>
								</tbody>
							</table>
							<div class="auditPlan">
								<table style="width: 100%;height: 100%;table-layout: fixed;">
									<tbody :class="{anim:animate==true}">
										<tr style="text-align: center;color: #fff;" v-for="(item,index) in AuditPlanList" @click="AuditPlanView(item)">
											<td style="border: 1px solid #fff;width: 20%;">
												<div style="width: 100%;height: 30.2px;display: flex;flex-direction: column;justify-content: center;">
													{{item.fomartDateTime}}
												</div>
											</td>
											<td style="border: 1px solid #fff;width: 60%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;text-align: left;">{{item.name}}</td>
											<td style="border: 1px solid #fff;width: 10%;">{{item.inspectorName}}</td>
											<td style="border: 1px solid #fff;width: 10%;" :style="{color: item.isDisplay ? '#ff0000' : '#fff'}">{{item.auditTaskStateName}}</td>
										</tr>
									</tbody>
								</table>
							</div> -->
						</div>
					</div>
				</div>
				<div class="column3">
					<div class="frame">
						<div class="Level">
							<div v-for="(item,index) in LevelList" style="width: 100%;height: 100%;"
								:id="'Level' + index" v-if="LevelList.length == 1">

							</div>
							<div v-for="(item,index) in LevelList" style="width: 50%;height: 100%;float: left;"
								:id="'Level' + index" v-if="LevelList.length == 2">

							</div>
							<div v-for="(item,index) in LevelList" style="width: 33.3%;height: 100%;float: left;"
								:id="'Level' + index" v-if="LevelList.length > 2">

							</div>
						</div>
					</div>
				</div>
				<div class="column2">
					<div class="frame">
						<div style="width: 100%;height: 50%;">
							<!-- <div style="width: 5%;height: 100%;position: relative;float: left;">
								<div class="icon-outer">
									<div class="icon-inner">
										
									</div>
								</div>
								<div style="position: absolute;left: 25px;top: 40px;font-size: 16px;color: #fff;width: 16px;" @click="IssueListView()">
									问题清单
								</div>
								
							</div> -->
							<div style="width: 100%;height: 18%;position: relative;">
								<div style="width: 100%;height: 50px;position: absolute;">
									<img src="../../../../public/img/image/tittle2.png" style="width: 100%;" />
								</div>
								<div style="position: absolute;left: 52px;top: 6px;font-size: 20px;color: #fff">
									问题清单
								</div>
							</div>
							<div style="width: 100%;height: 82%;">
								<div style="width: 100%;height: 100%;">
									<table
										style="width: 94%;height: 18%;margin-left: 3%;margin-top: 2%;background-color: #253f8c;">
										<tbody>
											<tr style="text-align: center;color: #fff">
												<th style="border: 1px solid #fff;width: 13%;">发现日期</th>
												<th style="border: 1px solid #fff;width: 20%;">问题描述</th>
												<th style="border: 1px solid #fff;width: 21%;">类别</th>
												<th style="border: 1px solid #fff;width: 13%;">区域</th>
												<th style="border: 1px solid #fff;width: 13%;">负责人</th>
												<th style="border: 1px solid #fff;width: 10%;">计划完成</th>
												<th style="border: 1px solid #fff;width: 10%;">实际完成</th>
											</tr>
										</tbody>
									</table>
									<div class="auditTask">
										<table style="width: 100%;height: 100%;table-layout: fixed;">
											<tbody :class="{anim:animate==true}">
												<tr style="text-align: center;color: #fff;height: 28px;"
													v-for="(item,index) in AuditTaskList" @click="IssueView(item)">
													<td style="border: 1px solid #fff;width: 13%;">
														{{item.discoveryTime}}</td>
													<td
														style="border: 1px solid #fff;width: 20%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;text-align: left;">
														{{item.description}}</td>
													<td
														style="border: 1px solid #fff;width: 21%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
														{{item.issueTypeListName}}</td>
													<td
														style="border: 1px solid #fff;width: 13%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
														{{item.auditPoint}}</td>
													<td style="border: 1px solid #fff;width: 13%;">{{item.respName}}
													</td>
													<td style="border: 1px solid #fff;width: 10%;"
														:style="{color: item.isDisplay ? '#ff0000' : '#fff'}">
														{{item.planEnd}}</td>
													<td style="border: 1px solid #fff;width: 10%;">{{item.closeTime}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div style="width: 100%;height: 50%;padding-top: 20px;">
							<div style="width: 100%;height: 100%;display: flex;" class="topBox">
								<div style="width: 50%;height: 100%;" id="IusseRegion">

								</div>
								<div style="width: 50%;height: 100%;" id="IusseClassify">

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row3">
				<div class="subtitle">
					<div
						style="width: 250px;height: 100%;display: flex;align-items: center;justify-content: center;position: relative;">
						<img src="../../../../public/img/image/luminescence1.png" style="height: 90%;width: 100%;" />
						<span style="position: absolute;">年度统计</span>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div style="width: 100%;height: 50px;position: absolute;">
							<img src="../../../../public/img/image/tittle1.png" style="width: 100%;" />
						</div>
						<div class="frame" id="PlanCompletionRate">

						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div style="width: 100%;height: 50px;position: absolute;">
							<img src="../../../../public/img/image/tittle1.png" style="width: 100%;" />
						</div>
						<div class="frame" id="CloseRate">

						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div style="width: 100%;height: 50px;position: absolute;">
							<img src="../../../../public/img/image/tittle1.png" style="width: 100%;" />
						</div>
						<div class="frame" id="ConformRate">

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import {
		KanBanServiceProxy,
		SessionServiceProxy
	} from '../../../shared/service-proxies';
	import {
		ModalHelper
	} from '@/shared/helpers';
	import AuditPlanView from "../../underlying-data/audit-task-view/audit-task-view.vue";
	import AuditTaskView from "../../underlying-data/audit-task/audit-task.vue";
	import IssueView from "../../underlying-data/problem-management/view/view.vue";
	import IssueListView from "../../underlying-data/problem-management/problem-management.vue";
	import AuditItemIssueStatistics from "../../report/Results-match/Results-match.vue";
	import MonthViewEmp from "../../underlying-data/report/month-view-emp/month-view-emp.vue";
	import * as echarts from 'echarts';
	import moment from "moment";
	let _this;

	export default {
		name: "LPA",
		mixins: [AppComponentBase],
		components: {
			AuditPlanView,
			AuditTaskView,
			IssueView,
			IssueListView,
			MonthViewEmp,
			AuditItemIssueStatistics
		},
		created() {
			_this = this;
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
			this.SessionServiceProxy = new SessionServiceProxy(this.$apiUrl, this.$api);
			setInterval(_this.getDataMaintenance, 60000);
			// setInterval(_this.getDepartmentalCompletionRate, 60000);
			// setInterval(_this.getDepartmentalCloseRate, 60000);
			// setInterval(_this.getDepartmentalConformRate, 60000);
			// setInterval(_this.getCompletionRate, 60000 * 60);
			// setInterval(_this.getCloseRate, 60000 * 60);
			// setInterval(_this.getConformRate, 60000 * 60);
			// setInterval(_this.getLevel, 60000);
			// setInterval(_this.getIusseRegion, 60000);
			// setInterval(_this.getIusseclassify, 60000);
		},
		mounted() {
			setInterval(this.NowData, 0);
			this.getToken();
			this.getDataMaintenance();
			this.KeyDown();
			// window.addEventListener("keydown", this.KeyDown, true);
		},
		data() {
			return {
				time: undefined,
				deptPlanCompletionRateList: [],
				deptPlanCompletionRate: [],
				departmentalCompletionData: [],
				deptCloseRateList: [],
				deptCloseRate: [],
				departmentalCloseData: [],
				deptConformList: [],
				deptConform: [],
				deptConformData: [],
				planCompletionRateList: [],
				planCompletionRate: [],
				completionData: [],
				closeRateList: [],
				closeRate: [],
				closeData: [],
				conformList: [],
				conform: [],
				conformData: [],
				LevelList: [],
				Level: [],
				LevelNumber: undefined,
				LevelData: [],
				IssueTypeData: [],
				IssuePointName: [],
				IssuePointData: [],
				AuditTaskList: [],
				EndAuditTaskId: undefined,
				AuditTaskTimer: undefined,
				animate: false,
				AuditPlanList: [],
				EndAuditPlanId: undefined,
				AuditPlanTimer: undefined,
				AuditPlanAnimate: false,
				LevelAnimate: false,
				FakeData: [],
				initial: true,
				skipCount: 0,
				maxResultCount:8
			}
		},
		computed: {
			option1() {
				return {
					title: {
						text: '部门计划完成率',
						y: '2.2%',
						x: '10%',
						textStyle: {
							fontSize: '20px',
							fontWeight: 'normal',
							color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					color: ['#fac858'],
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let sum = 0;
							let length = this.deptPlanCompletionRate.deptList.length;
							for (let i = 0; i < length; i++) {
								if (this.deptPlanCompletionRate.deptList[i].name === params[0].axisValue) {
									sum = this.deptPlanCompletionRate.planList[i];
								}
							}
							return params[0].axisValue + '<br />' + params[0].seriesName + '：' + params[0].value +
								'<br />' + params[1].seriesName + '：' + params[1].value + '<br />总数：' + sum +
								'<br />' + params[2].seriesName + '：' + params[2].value + '%'
						}
					},
					legend: {
						right: '5%',
						top: '11%',
						textStyle: {
							color: '#fff'
						}
					},
					// calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptPlanCompletionRateList,
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#fff']
							}
						},
						axisLabel: {
							lineStyle: {
								color: '#fff'
							},
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if (_this.deptPlanCompletionRateList.length > 8) {
									provideNumber = 2;
								} else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if (number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								} else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							minInterval: 1,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('完成率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.departmentalCompletionData,
				}
			},
			option2() {
				return {
					title: {
						text: '部门问题关闭率',
						y: '2.2%',
						x: '10%',
						textStyle: {
							fontSize: '20px',
							fontWeight: 'normal',
							color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					color: ['#fac858'],
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let sum = 0;
							let length = this.deptCloseRate.deptList.length;
							for (let i = 0; i < length; i++) {
								if (this.deptCloseRate.deptList[i].name === params[0].axisValue) {
									sum = this.deptCloseRate.issueList[i];
								}
							}
							return params[0].axisValue + '<br />' + params[0].seriesName + '：' + params[0].value +
								'<br />' + params[1].seriesName + '：' + params[1].value + '<br />总数：' + sum +
								'<br />' + params[2].seriesName + '：' + params[2].value + '%'
						}
					},
					legend: {
						right: '5%',
						top: '11%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptCloseRateList,
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if (_this.deptCloseRateList.length > 8) {
									provideNumber = 2;
								} else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if (number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								} else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							minInterval: 1,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('关闭率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.departmentalCloseData,
				}
			},
			option3() {
				return {
					title: {
						text: '区域符合率',
						y: '2.2%',
						x: '10%',
						textStyle: {
							fontSize: '20px',
							fontWeight: 'normal',
							color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					color: ['#fac858'],
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let sum = 0;
							let length = this.deptConform.deptList.length;
							for (let i = 0; i < length; i++) {
								if (this.deptConform.deptList[i].name === params[0].axisValue) {
									sum = this.deptConform.auditItemList[i];
								}
							}
							return params[0].axisValue + '<br />' + params[0].seriesName + '：' + params[0].value +
								'<br />' + params[1].seriesName + '：' + params[1].value + '<br />总数：' + sum +
								'<br />' + params[2].seriesName + '：' + params[2].value + '%'
						}
					},
					legend: {
						right: '5%',
						top: '11%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptConformList,
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if (_this.deptConformList.length > 8) {
									provideNumber = 2;
								} else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if (number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								} else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							minInterval: 1,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('符合率(%)'),
							min: 50,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.deptConformData,
				}
			},
			option4() {
				return {
					title: {
						text: '计划完成率',
						y: '2.2%',
						x: '10%',
						textStyle: {
							fontSize: '20px',
							fontWeight: 'normal',
							color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					color: ['#fac858'],
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let sum = 0;
							let length = this.planCompletionRate.monthList.length;
							for (let i = 0; i < length; i++) {
								if (this.planCompletionRate.monthList[i] === params[0].axisValue) {
									sum = this.planCompletionRate.planList[i];
								}
							}
							return params[0].axisValue + '<br />' + params[0].seriesName + '：' + params[0].value +
								'<br />' + params[1].seriesName + '：' + params[1].value + '<br />总数：' + sum +
								'<br />' + params[2].seriesName + '：' + params[2].value + '%'
						}
					},
					legend: {
						right: '5%',
						top: '11%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.planCompletionRateList,
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							minInterval: 1,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('完成率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.completionData,
				}
			},
			option5() {
				return {
					title: {
						text: '问题关闭率',
						y: '2.2%',
						x: '10%',
						textStyle: {
							fontSize: '20px',
							fontWeight: 'normal',
							color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					color: ['#fac858'],
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let sum = 0;
							let length = this.closeRate.monthList.length;
							for (let i = 0; i < length; i++) {
								if (this.closeRate.monthList[i] === params[0].axisValue) {
									sum = this.closeRate.issueList[i];
								}
							}
							return params[0].axisValue + '<br />' + params[0].seriesName + '：' + params[0].value +
								'<br />' + params[1].seriesName + '：' + params[1].value + '<br />总数：' + sum +
								'<br />' + params[2].seriesName + '：' + params[2].value + '%'
						}
					},
					legend: {
						right: '5%',
						top: '11%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.closeRateList,
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							minInterval: 1,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('关闭率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.closeData,
				}
			},
			option6() {
				return {
					title: {
						text: '符合率',
						y: '2.2%',
						x: '10%',
						textStyle: {
							fontSize: '20px',
							fontWeight: 'normal',
							color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					color: ['#fac858'],
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let sum = 0;
							let length = this.conform.monthList.length;
							for (let i = 0; i < length; i++) {
								if (this.conform.monthList[i] === params[0].axisValue) {
									sum = this.conform.auditItemList[i];
								}
							}
							return params[0].axisValue + '<br />' + params[0].seriesName + '：' + params[0].value +
								'<br />' + params[1].seriesName + '：' + params[1].value + '<br />总数：' + sum +
								'<br />' + params[2].seriesName + '：' + params[2].value + '%'
						}
					},
					legend: {
						right: '5%',
						top: '11%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.conformList,
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							minInterval: 1,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('符合率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show: false,
								lineStyle: {
									color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.conformData,
				}
			},
			option8() {
				return {
					title: {
						text: "问题区域TOP3",
						// text: "问\n题\n区\n域\nT\nO\nP\n3",
						left: 60,
						top: '10%',
						textStyle: {
							fontSize: 14,
							fontWeight: 'normal',
							color: '#fff'
						},
						textAlign: 'center'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow',
						},
					},
					grid: {
						top: 40,
						left: '5%',
						bottom: 15,
						containLabel: true,
					},

					legend: {
						orient: 'vertical',
						top: '50'
					},
					calculable: true,
					xAxis: {
						position: 'top',
						minInterval: 1,
						type: 'value',
						boundaryGap: [0, 0.01],
						axisLabel: {
							show: false,
						},
						splitLine: {
							show: false
						},
					},
					yAxis: {
						type: 'category',
						data: this.IssuePointName,
						inverse: true,
						axisLabel: {
							color: '#fff'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						}
					},
					series: [{
						// name: this.l('FeedbackIssueNum'),
						type: 'bar',
						barMaxWidth: 50, //最大宽度
						data: this.IssuePointData,
						label: {
							show: true,
						},
					}, ],
				}
			},
			option9() {
				return {
					title: {
						text: "问题分类TOP3",
						// text: "问\n题\n分\n类\nT\nO\nP\n3",
						left: 40,
						top: '10%',
						textStyle: {
							fontSize: 14,
							fontWeight: 'normal',
							color: '#fff'
						},
						textAlign: 'center'
					},
					tooltip: {
						trigger: 'item',
						formatter: '{b} {c}({d}%)'
					},
					series: {
						type: 'pie',
						center: ['51%', '54%'],
						radius: ['40%','65%'],
						data: this.IssueTypeData,
						avoidLabelOverlap: false,
						label: {
							color: '#fff',
							textBorderWidth: '0'
						}
					}
				}
			}
		},
		methods: {
			//时间
			NowData() {
				let aData = new Date();
				let year = aData.getFullYear();
				let month = (aData.getMonth() + 1);
				let day = aData.getDate();
				let Hour = aData.getHours();
				let Minute = aData.getMinutes();
				let Second = aData.getSeconds();
				month = month < 10 ? '0' + month : month;
				day = day < 10 ? '0' + day : day;
				Hour = Hour < 10 ? '0' + Hour : Hour;
				Minute = Minute < 10 ? '0' + Minute : Minute;
				Second = Second < 10 ? '0' + Second : Second;
				this.time = year + "-" + month + "-" + day + " " + Hour + ":" + Minute + ":" + Second;
			},

			getToken() {
				this.SessionServiceProxy.updateUserSignInToken(

				).finally(() => {

				}).then(res => {
					console.log(res)
				})
			},

			//维护的数据
			getDataMaintenance() {
				this.KanBanServiceProxy.getAll(

				).finally(() => {

				}).then(res => {
					this.FakeData = res;
					this.getDepartmentalCompletionRate();
					this.getDepartmentalCloseRate();
					this.getDepartmentalConformRate();
					this.getCompletionRate();
					this.getCloseRate();
					this.getConformRate();
					this.getLevel();
					this.getIusseRegion();
					this.getIusseclassify();
					if (this.initial) {
						this.getAuditPlan();
						this.getAuditTaskListInput();
						this.initial = false;
					}
				})
			},

			//部门计划完成率
			getDepartmentalCompletionRate() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Departmental-Plan-Completion-Rate") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getDeptCompletionRate(

							).finally(() => {

							}).then(res => {
								this.deptPlanCompletionRate = res;
								if (res.deptList.length < 3) {
									for (let i = res.deptList.length; i < 3; i++) {
										res.deptList.push({
											name: ''
										});
									}
								}

								if (res.finshList.length < 3) {
									for (let i = res.finshList.length; i < 3; i++) {
										res.finshList.push(0);
									}
								}

								if (res.noFinshList.length < 3) {
									for (let i = res.noFinshList.length; i < 3; i++) {
										res.noFinshList.push(0);
									}
								}

								if (res.finshRateList.length < 3) {
									for (let i = res.finshRateList.length; i < 3; i++) {
										res.finshRateList.push('');
									}
								}

								let data = [];
								for (let i in res.deptList) {
									let item = {
										name: res.deptList[i].name,
										id: res.deptList[i].id,
										finshList: res.finshList[i],
										noFinshList: res.noFinshList[i],
										finshRateList: res.finshRateList[i],
										num: res.finshList[i] + res.noFinshList[i]
									};
									data.push(item);
								}
								data = data.sort(function(a, b) {
									return b.name - a.name
								});

								let name = [];
								let completedNumber = [];
								let NoCompletionNumber = [];
								let CompletedRate = [];
								for (let i in data) {
									name.push(data[i].name);
									let comp = {
										value: data[i].finshList,
										id: data[i].id
									};
									let NoComp = {
										value: data[i].noFinshList,
										id: data[i].id
									};
									completedNumber.push(comp);
									NoCompletionNumber.push(NoComp);
									CompletedRate.push(data[i].finshRateList);
								}

								this.deptPlanCompletionRateList = name;
								let completed = {
									name: "完成",
									type: 'bar',
									stack: 'bar',
									data: completedNumber,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
								};

								let NoCompletion = {
									name: "未完成",
									type: 'bar',
									stack: 'bar',
									data: NoCompletionNumber,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#01b2ff' },
										{ offset: 0.5, color: '#0277ff' },
										{ offset: 1, color: '#0337ff' }
										])
									}
								};

								let completedRate = {
									name: "完成率",
									type: 'line',
									yAxisIndex: 1,
									data: CompletedRate,
									label: {
										show: true,
										color: '#fff',
										fontSize: '8px',
										textBorderWidth: '0',
										formatter: function(data) {
											if (data.value > 0) {
												return data.value + '%'
											} else {
												return ""
											}
										}
									}
								};

								this.departmentalCompletionData = [];
								this.departmentalCompletionData.push(completed);
								this.departmentalCompletionData.push(NoCompletion);
								this.departmentalCompletionData.push(completedRate);

								this.DepartmentalCompletionRateCharts(1);
							})
						} else {
							let data = JSON.parse(this.FakeData[f].value);
							let name = [];
							let completedNumber = [];
							let NoCompletionNumber = [];
							let completedRateNumber = [];
							for (let i in data) {
								data[i].num = data[i].finsh + data[i].noFinsh;
							}

							data = data.sort(function(a, b) {
								return b.name - a.name
							});

							for (let i in data) {
								name.push(data[i].departmentName);
								completedNumber.push(data[i].finsh);
								NoCompletionNumber.push(data[i].noFinsh);
								completedRateNumber.push(data[i].finshRate);
							}

							this.deptPlanCompletionRateList = name;
							let completed = {
								name: "完成",
								type: 'bar',
								stack: 'bar',
								data: completedNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								},
								itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
							};

							let NoCompletion = {
								name: "未完成",
								type: 'bar',
								stack: 'bar',
								data: NoCompletionNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								},
								itemStyle: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#01b2ff' },
									{ offset: 0.5, color: '#0277ff' },
									{ offset: 1, color: '#0337ff' }
									])
								}
							};

							let completedRate = {
								name: "完成率",
								type: 'line',
								yAxisIndex: 1,
								data: completedRateNumber,
								label: {
									show: true,
									color: '#fff',
									fontSize: '8px',
									textBorderWidth: '0',
									formatter: function(data) {
										if (data.value > 0) {
											return data.value + '%'
										} else {
											return ""
										}
									}
								}
							};
							console.log(completed)
							console.log(NoCompletion)
							console.log(completedRate)
							this.departmentalCompletionData = [];
							this.departmentalCompletionData.push(completed);
							this.departmentalCompletionData.push(NoCompletion);
							this.departmentalCompletionData.push(completedRate);

							this.DepartmentalCompletionRateCharts(2);
						}
					}
				}
			},

			DepartmentalCompletionRateCharts(type) {
				this.myeChart = echarts.init(document.getElementById("DepartmentalPlanCompletionRate"));

				this.myeChart.off('click');
				this.myeChart.clear();

				this.myeChart.setOption(this.option1);
				if (type == 1) {
					this.myeChart.on('click', function(params) {
						let id = params.data.id;
						ModalHelper.create(MonthViewEmp, {
							id: id
						}, {
							width: 1200
						}).subscribe(res => {

						})
					});
				}

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//部门问题关闭率
			getDepartmentalCloseRate() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Departmental-Issue-Closure-Rate") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getDeptIssueCloseRate(

							).finally(() => {

							}).then(res => {
								console.log(res)
								this.deptCloseRate = res;
								if (res.deptList.length < 3) {
									for (let i = res.deptList.length; i < 3; i++) {
										res.deptList.push({
											name: ''
										});
									}
								}

								if (res.closeList.length < 3) {
									for (let i = res.closeList.length; i < 3; i++) {
										res.closeList.push(0);
									}
								}

								if (res.noCloseList.length < 3) {
									for (let i = res.noCloseList.length; i < 3; i++) {
										res.noCloseList.push(0);
									}
								}

								if (res.closeRateList.length < 3) {
									for (let i = res.closeRateList.length; i < 3; i++) {
										res.closeRateList.push('');
									}
								}

								let name = [];
								let closeNumber = [];
								let noCloseNumber = [];
								for (let i in res.deptList) {
									name.push(res.deptList[i].name);
									let close = {
										value: res.closeList[i],
										id: res.deptList[i].id
									};
									let noClose = {
										value: res.noCloseList[i],
										id: res.deptList[i].id
									};
									closeNumber.push(close);
									noCloseNumber.push(noClose);
								}

								this.deptCloseRateList = name;

								let closeList = {
									name: "关闭",
									type: 'bar',
									stack: 'bar',
									data: closeNumber,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
								};

								let noCloseList = {
									name: "未关闭",
									type: 'bar',
									stack: 'bar',
									data: noCloseNumber,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#01b2ff' },
										{ offset: 0.5, color: '#0277ff' },
										{ offset: 1, color: '#0337ff' }
										])
									}
								};

								let closeRateList = {
									name: "关闭率",
									type: 'line',
									yAxisIndex: 1,
									data: res.closeRateList,
									label: {
										show: true,
										color: '#fff',
										fontSize: '8px',
										textBorderWidth: '0',
										formatter: function(data) {
											if (data.value > 0) {
												return data.value + '%'
											} else {
												return ""
											}
										}
									}
								};

								this.departmentalCloseData = [];
								this.departmentalCloseData.push(closeList);
								this.departmentalCloseData.push(noCloseList);
								this.departmentalCloseData.push(closeRateList);
								console.log(this.departmentalCloseData)
								this.DepartmentalCloseRateCharts(1);
							})
						} else {
							let data = JSON.parse(this.FakeData[f].value);
							let name = [];
							let closeNumber = [];
							let noCloseNumber = [];
							let closeRateNumber = [];
							for (let i in data) {
								name.push(data[i].departmentName);
								closeNumber.push(data[i].close);
								noCloseNumber.push(data[i].noClose);
								closeRateNumber.push(data[i].closeRate);
							}

							this.deptCloseRateList = name;

							let closeList = {
								name: "关闭",
								type: 'bar',
								stack: 'bar',
								data: closeNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								},
								itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
							};

							let noCloseList = {
								name: "未关闭",
								type: 'bar',
								stack: 'bar',
								data: noCloseNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								},
								itemStyle: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#01b2ff' },
									{ offset: 0.5, color: '#0277ff' },
									{ offset: 1, color: '#0337ff' }
									])
								}
							};

							let closeRateList = {
								name: "关闭率",
								type: 'line',
								yAxisIndex: 1,
								data: closeRateNumber,
								label: {
									show: true,
									color: '#fff',
									fontSize: '8px',
									textBorderWidth: '0',
									formatter: function(data) {
										if (data.value > 0) {
											return data.value + '%'
										} else {
											return ""
										}
									}
								}
							};

							this.departmentalCloseData = [];
							this.departmentalCloseData.push(closeList);
							this.departmentalCloseData.push(noCloseList);
							this.departmentalCloseData.push(closeRateList);

							this.DepartmentalCloseRateCharts(2);
						}
					}
				}
			},

			DepartmentalCloseRateCharts(type) {
				this.myeChart = echarts.init(document.getElementById("DepartmentalCloseRate"));

				this.myeChart.off('click');
				this.myeChart.clear();

				this.myeChart.setOption(this.option2);

				if (type == 1) {
					this.myeChart.on('click', function(params) {
						let id = params.data.id;
						ModalHelper.create(IssueListView, {
							month: 1
						}, {
							width: 1200
						}).subscribe(res => {

						})
					});
				}

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//部门符合率
			getDepartmentalConformRate() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Departmental-Compliance-Rate") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getDeptComplianceRate(

							).finally(() => {

							}).then(res => {
								res.noConformList = [];
								for (let i = 0; i < res.deptList.length; i++) {
									res.noConformList.push(res.auditItemList[i] - res.issueList[i]);
								}
								if (res.deptList.length < 3) {
									for (let i = res.deptList.length; i < 3; i++) {
										res.deptList.push({
											name: ''
										});
									}
								}

								if (res.issueList.length < 3) {
									for (let i = res.issueList.length; i < 3; i++) {
										res.issueList.push(0);
									}
								}
								if (res.complianceRateList.length < 3) {
									for (let i = res.complianceRateList.length; i < 3; i++) {
										res.complianceRateList.push('');
									}
								}
								if (res.noConformList.length < 3) {
									for (let i = res.noConformList.length; i < 3; i++) {
										res.noConformList.push('');
									}
								}

								let name = [];
								let completedNumber = [];
								let noCompletedNumber = [];
								for (let i in res.deptList) {
									name.push(res.deptList[i].name);
									let comp = {
										value: res.issueList[i],
										id: res.deptList[i].id
									};
									completedNumber.push(comp);
									let noComp = {
										value: res.noConformList[i],
										id: res.deptList[i].id
									};
									noCompletedNumber.push(noComp)
								}
								this.deptConform = res;
								this.deptConformList = name;
								let issueList = {
									name: "符合",
									type: 'bar',
									stack: 'bar',
									data: completedNumber,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
									
								};

								let noConformList = {
									name: "不符合",
									type: 'bar',
									stack: 'bar',
									data: noCompletedNumber,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#01b2ff' },
										{ offset: 0.5, color: '#0277ff' },
										{ offset: 1, color: '#0337ff' }
										])
									}
								};

								let complianceRateList = {
									name: "符合率",
									type: 'line',
									yAxisIndex: 1,
									data: res.complianceRateList,
									label: {
										show: true,
										color: '#fff',
										fontSize: '8px',
										textBorderWidth: '0',
										formatter: function(data) {
											if (data.value > 0) {
												return data.value + '%'
											} else {
												return ""
											}
										}
									}
								};

								this.deptConformData = [];
								this.deptConformData.push(issueList);
								this.deptConformData.push(noConformList);
								this.deptConformData.push(complianceRateList);

								this.DepartmentalConformRateCharts(1);
							})
						} else {
							let data = JSON.parse(this.FakeData[f].value);
							let name = [];
							let completedNumber = [];
							let complianceRateNumber = [];
							for (let i in data) {
								name.push(data[i].departmentName);
								completedNumber.push(data[i].issueNumber);
								complianceRateNumber.push(data[i].complianceRate);
							}
							this.deptConformList = name;
							let issueList = {
								name: "问题",
								type: 'bar',
								stack: 'bar',
								data: completedNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								}
							};

							let complianceRateList = {
								name: "符合率",
								type: 'line',
								yAxisIndex: 1,
								data: complianceRateNumber,
								label: {
									show: true,
									color: '#fff',
									fontSize: '8px',
									textBorderWidth: '0',
									formatter: function(data) {
										if (data.value > 0) {
											return data.value + '%'
										} else {
											return ""
										}
									}
								},
								
							};

							this.deptConformData = [];
							this.deptConformData.push(issueList);
							this.deptConformData.push(complianceRateList);

							this.DepartmentalConformRateCharts(2);
						}
					}
				}
			},

			DepartmentalConformRateCharts(type) {
				this.myeChart = echarts.init(document.getElementById("DepartmentalConformRate"));

				this.myeChart.off('click');
				this.myeChart.clear();

				this.myeChart.setOption(this.option3);

				if (type == 1) {
					this.myeChart.on('click', function(params) {
						let id = "";
						// params.data.tasklist.forEach(item => {
						//     id = item.id
						// });
						ModalHelper.create(AuditItemIssueStatistics, {

						}, {
							width: 1200
						}).subscribe(res => {

						})
					});
				}

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//计划完成率
			getCompletionRate() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Plan-Completion-Rate") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getYearCompletionRate(

							).finally(() => {

							}).then(res => {
								this.planCompletionRate = res;
								this.planCompletionRateList = res.monthList;
								this.planCompletionRate = res;
								let month = new Date().getMonth();
								res.noFinshList[month] = {
									value: res.noFinshList[month],
									itemStyle: {
										color: '#91cc75'
									}
								};
								let completed = {
									name: "完成",
									type: 'bar',
									stack: 'bar',
									data: res.finshList,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
								};

								let NoCompletion = {
									name: "未完成",
									type: 'bar',
									stack: 'bar',
									data: res.noFinshList,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#fd4f49' },
										{ offset: 0.5, color: '#ff0000' },
										{ offset: 1, color: '#be2e24' }
										])
									}
								};

								let completedRate = {
									name: "完成率",
									type: 'line',
									yAxisIndex: 1,
									data: res.finshRateList,
									label: {
										show: true,
										color: '#fff',
										fontSize: '8px',
										textBorderWidth: '0',
										formatter: function(data) {
											if (data.value > 0) {
												return data.value + '%'
											} else {
												return ""
											}
										}
									}
								};

								this.completionData = [];
								this.completionData.push(completed);
								this.completionData.push(NoCompletion);
								this.completionData.push(completedRate);

								this.CompletionRateCharts(1);
							})
						} else {
							let data = JSON.parse(this.FakeData[f].value);
							let month = [];
							let finshNumber = [];
							let noFinshNumber = [];
							let finshRateNumber = [];
							for (let i in data) {
								month.push(data[i].month);
								finshNumber.push(data[i].finsh);
								noFinshNumber.push(data[i].noFinsh);
								finshRateNumber.push(data[i].finshRate);
							}

							this.planCompletionRateList = month;
							let completed = {
								name: "完成",
								type: 'bar',
								stack: 'bar',
								data: finshNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								}
							};

							let NoCompletion = {
								name: "未完成",
								type: 'bar',
								stack: 'bar',
								data: noFinshNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								}
							};

							let completedRate = {
								name: "完成率",
								type: 'line',
								yAxisIndex: 1,
								data: finshRateNumber,
								label: {
									show: true,
									color: '#fff',
									fontSize: '8px',
									textBorderWidth: '0',
									formatter: function(data) {
										if (data.value > 0) {
											return data.value + '%'
										} else {
											return ""
										}
									}
								}
							};

							this.completionData = [];
							this.completionData.push(completed);
							this.completionData.push(NoCompletion);
							this.completionData.push(completedRate);

							this.CompletionRateCharts(1);
						}
					}
				}
			},

			CompletionRateCharts(type) {
				this.myeChart = echarts.init(document.getElementById("PlanCompletionRate"));

				this.myeChart.off('click');
				this.myeChart.clear();

				this.myeChart.setOption(this.option4);

				if (type == 1) {
					this.myeChart.on('click', function(params) {
						let date = moment(new Date()).format('YYYY') + '-' + params.name;
						ModalHelper.create(MonthViewEmp, {
							kanbanDate: date
						}, {
							width: 1200,
							height: 800
						}).subscribe(res => {

						})
					});
				}

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//问题关闭率
			getCloseRate() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Issue-Closure-Rate") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getYearIssueCloseRate(

							).finally(() => {

							}).then(res => {
								this.closeRateList = res.monthList;
								this.closeRate = res;
								let closeList = {
									name: "关闭",
									type: 'bar',
									stack: 'bar',
									data: res.closeList,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
								};

								let noCloseList = {
									name: "未关闭",
									type: 'bar',
									stack: 'bar',
									data: res.noCloseList,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#01b2ff' },
										{ offset: 0.5, color: '#0277ff' },
										{ offset: 1, color: '#0337ff' }
										])
									}
								};

								let closeRateList = {
									name: "关闭率",
									type: 'line',
									yAxisIndex: 1,
									data: res.closeRateList,
									label: {
										show: true,
										color: '#fff',
										fontSize: '8px',
										textBorderWidth: '0',
										formatter: function(data) {
											if (data.value > 0) {
												return data.value + '%'
											} else {
												return ""
											}
										}
									}
								};

								this.closeData = [];
								this.closeData.push(closeList);
								this.closeData.push(noCloseList);
								this.closeData.push(closeRateList);

								this.CloseRateCharts(1);
							})
						} else {
							let data = JSON.parse(this.FakeData[f].value);
							let month = [];
							let closeNumber = [];
							let noCloseNumber = [];
							let closeRateNumber = [];
							for (let i in data) {
								month.push(data[i].month);
								closeNumber.push(data[i].close);
								noCloseNumber.push(data[i].noClose);
								closeRateNumber.push(data[i].closeRate);
							}

							this.closeRateList = month;
							let closeList = {
								name: "关闭",
								type: 'bar',
								stack: 'bar',
								data: closeNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								}
							};

							let noCloseList = {
								name: "未关闭",
								type: 'bar',
								stack: 'bar',
								data: noCloseNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								}
							};

							let closeRateList = {
								name: "关闭率",
								type: 'line',
								yAxisIndex: 1,
								data: closeRateNumber,
								label: {
									show: true,
									color: '#fff',
									fontSize: '8px',
									textBorderWidth: '0',
									formatter: function(data) {
										if (data.value > 0) {
											return data.value + '%'
										} else {
											return ""
										}
									}
								}
							};

							this.closeData = [];
							this.closeData.push(closeList);
							this.closeData.push(noCloseList);
							this.closeData.push(closeRateList);

							this.CloseRateCharts(2);
						}
					}
				}
			},

			CloseRateCharts(type) {
				this.myeChart = echarts.init(document.getElementById("CloseRate"));

				this.myeChart.off('click');
				this.myeChart.clear();

				this.myeChart.setOption(this.option5);

				if (type == 1) {
					this.myeChart.on('click', function(params) {
						let year = moment(new Date()).format('YYYY');
						let startTime = new Date(year, params.name - 1, 1);
						let endTime = new Date(startTime.getFullYear(), startTime.getMonth() + 1, 0);
						ModalHelper.create(IssueListView, {
							startTime: startTime,
							endTime: endTime
						}, {
							width: 1200
						}).subscribe(res => {

						})
					});
				}

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//符合率
			getConformRate() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Compliance-Rate") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getYearComplianceRate(

							).finally(() => {

							}).then(res => {
								this.conformList = res.monthList;
								this.conform = res;
								res.noConformList = [];
								for (let i = 0; i < 12; i++) {
									res.noConformList.push(res.auditItemList[i] - res.issueList[i]);
								}
								let issueList = {
									name: "符合",
									type: 'bar',
									stack: 'bar',
									data: res.issueList,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#32fd99' },
										{ offset: 0.5, color: '#15be93' },
										{ offset: 1, color: '#01928e' }
										])
									}
								};

								let noConformList = {
									name: "不符合",
									type: 'bar',
									stack: 'bar',
									data: res.noConformList,
									barMaxWidth: 20,
									label: {
										show: true,
										color: "#fff",
										formatter: function(data) {
											if (data.value > 0) {
												return data.value;
											} else {
												return '';
											}
										}
									},
									itemStyle: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#01b2ff' },
										{ offset: 0.5, color: '#0277ff' },
										{ offset: 1, color: '#0337ff' }
										])
									}
								};

								let complianceRateList = {
									name: "符合率",
									type: 'line',
									yAxisIndex: 1,
									data: res.complianceRateList,
									label: {
										show: true,
										color: '#fff',
										fontSize: '8px',
										textBorderWidth: '0',
										formatter: function(data) {
											if (data.value > 0) {
												return data.value + '%'
											} else {
												return ""
											}
										}
									}
								};

								this.conformData = [];
								this.conformData.push(issueList);
								this.conformData.push(noConformList);
								this.conformData.push(complianceRateList);

								this.ConformRateCharts(1);
							})
						} else {
							let data = JSON.parse(this.FakeData[f].value);
							let month = [];
							let issueNumber = [];
							let complianceRateNumber = [];
							for (let i in data) {
								month.push(data[i].month);
								issueNumber.push(data[i].issueNumber);
								complianceRateNumber.push(data[i].complianceRate);
							}

							this.conformList = month;
							let issueList = {
								name: "问题",
								type: 'bar',
								stack: 'bar',
								data: issueNumber,
								barMaxWidth: 20,
								label: {
									show: true,
									color: "#fff",
									formatter: function(data) {
										if (data.value > 0) {
											return data.value;
										} else {
											return '';
										}
									}
								}
							};

							let complianceRateList = {
								name: "符合率",
								type: 'line',
								yAxisIndex: 1,
								data: complianceRateNumber,
								label: {
									show: true,
									color: '#fff',
									fontSize: '8px',
									textBorderWidth: '0',
									formatter: function(data) {
										if (data.value > 0) {
											return data.value + '%'
										} else {
											return ""
										}
									}
								}
							};

							this.conformData = [];
							this.conformData.push(issueList);
							this.conformData.push(complianceRateList);

							this.ConformRateCharts(2);
						}
					}
				}
			},

			ConformRateCharts(type) {
				this.myeChart = echarts.init(document.getElementById("ConformRate"));

				this.myeChart.off('click');
				this.myeChart.clear();

				this.myeChart.setOption(this.option6);

				if (type == 1) {
					this.myeChart.on('click', function(params) {
						let year = moment(new Date()).format('YYYY');
						let startTime = new Date(year, params.name - 1, 1);
						let endTime = new Date(startTime.getFullYear(), startTime.getMonth() + 1, 0);
						ModalHelper.create(AuditItemIssueStatistics, {
							startTime: startTime,
							endTime: endTime
						}, {
							width: 1200
						}).subscribe(res => {

						})
					});
				}

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//审核计划
			getAuditPlan() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Audit-Plan") {
						if (this.FakeData[f].isDockingData) {
							let date = new Date();
							this.KanBanServiceProxy.getAuditTaskList(
								date,
								date,
								undefined,
								undefined,
								undefined,
								undefined,
								undefined,
								this.maxResultCount,
								this.skipCount
							).finally(() => {

							}).then(res => {
								if(res.length >= 8) {
									this.skipCount += 8;
								}else {
									this.skipCount = 0;
								}
								if(res.length === 0) {
									this.getAuditPlan();
									return;
								}
								this.AuditPlanList = res;
								this.AuditPlanList.map(item => {
									item.isDisplay = false;
									if (item.auditTaskState == "4") {
										if (item.planEnd) {
											let nowDate = new Date();
											if (Date.parse(item.planEnd) < Date.parse(nowDate)) {
												item.isDisplay = true;
											}
										}
									}
								});

								setTimeout(() => {
									this.getAuditPlan();
								}, 10000);
								// if(this.AuditPlanList.length != 0) {
								// 	this.EndAuditPlanId = this.AuditPlanList[this.AuditPlanList.length - 1].id;
								// }

								// if (this.AuditPlanList.length > 8) {
								// 	this.AuditPlanTimer = setInterval(this.AuditPlanScroll, 5000);
								// } else if (this.AuditPlanList.length  == 5) {
								// 	clearInterval(this.AuditPlanTimer)
								// } else if (this.AuditPlanList.length < 5) {
								// 	for(let i=this.AuditPlanList.length;i<5;i++) {
								// 		let data = {
								// 			fomartDateTime : '',
								// 			name : '',
								// 			inspectorName : '',
								// 			auditTaskStateName : '',
								// 			isDisplay: false
								// 		};
								// 		this.AuditPlanList.push(data);
								// 	}
								// 	clearInterval(this.AuditPlanTimer)
								// }
							})
						} else {
							this.AuditPlanList = JSON.parse(this.FakeData[f].value);
							for (let l in this.AuditPlanList) {
								this.AuditPlanList[l].name = this.AuditPlanList[l].planName;
							}

							setTimeout(() => {
								this.getAuditPlan();
							}, 30000);
							// if(this.AuditPlanList.length != 0) {
							// 	this.EndAuditPlanId = this.AuditPlanList[this.AuditPlanList.length - 1].id;
							// }

							// if (this.AuditPlanList.length > 5) {
							// 	this.AuditPlanTimer = setInterval(this.AuditPlanScroll, 5000);
							// } else if (this.AuditPlanList.length  == 5) {
							// 	clearInterval(this.AuditPlanTimer);
							// 	setTimeout(() => {
							// 		this.getAuditPlan();
							// 	}, 30000);
							// } else if (this.AuditPlanList.length < 5) {
							// 	for(let i=this.AuditPlanList.length;i<5;i++) {
							// 		let data = {
							// 			fomartDateTime : '',
							// 			name : '',
							// 			inspectorName : '',
							// 			auditTaskStateName : '',
							// 			isDisplay: false
							// 		};
							// 		this.AuditPlanList.push(data);
							// 	}
							// 	clearInterval(this.AuditPlanTimer)
							// 	setTimeout(() => {
							// 		this.getAuditPlan();
							// 	}, 30000);
							// }
						}
					}
				}
			},

			AuditPlanScroll() {
				this.AuditPlanAnimate = true;
				setTimeout(() => {
					this.AuditPlanList.push(this.AuditPlanList[0]);
					this.AuditPlanList.shift();
					this.AuditPlanAnimate = false;
					if (this.AuditPlanList[0].id === this.EndAuditPlanId) {
						this.getAuditPlan();
						clearInterval(this.AuditPlanTimer)
					}
				}, 500);
			},

			//层级
			getLevel() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Level") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getUserLevel(

							).finally(() => {

							}).then(res => {
								this.LevelList = res;

								// setTimeout(this.LevelCharts(), 3000)
								this.LevelCharts();
							})
						} else {
							if (this.FakeData[f].value) {
								this.LevelList = JSON.parse(this.FakeData[f].value);
							}
							this.LevelCharts();
						}
					}
				}
			},

			LevelCharts() {
				for (let i in this.LevelList) {
					let Level;
					if (this.LevelList[i].levelName.length > 15) {
						Level = this.LevelList[i].levelName.substring(0, 15);
						if (this.LevelList[i].levelName.length > 30) {
							Level += '\n' + this.LevelList[i].levelName.substring(15, 27) + '...';
						} else {
							Level += '\n' + this.LevelList[i].levelName.substring(15, 30);
						}
					} else {
						Level = this.LevelList[i].levelName;
					}
					let LevelNumber = this.LevelList[i].planCount;
					let LevelData = [{
							value: Math.round(this.LevelList[i].finshRate),
							name: this.LevelList[i].TypeName
						},
						{
							value: 100 - this.LevelList[i].finshRate,
							name: 'NotReached'
						}
					];

					this.$nextTick(() => {
						this.myeChart = echarts.init(document.getElementById("Level" + i));

						this.myeChart.clear();

						this.myeChart.setOption({
							title: {
								text: Level,
								left: 'center',
								top: '30%',
								textStyle: {
									// fontSize: this.echartsTitle,
									fontWeight: 'normal',
									color: '#fff'
								},
								subtext: '进度：' + LevelData[0].value + '%\n总计划：' + LevelNumber,
								subtextStyle: {
									color: '#fff',
									fontSize: 16,
									lineHeight: 20
								}
							},

							color: ['#60c34f', '#f6f6f6'],
							series: [{
								type: 'pie',
								center: ['50%', '50%'],
								radius: ['60%', '75%'],
								avoidLabelOverlap: false,
								label: {
									show: false,
									position: 'center'
								},
								labelLine: {
									show: false
								},
								data: LevelData
							}],
						});

						this.myeChart.resize();
						window.addEventListener('resize', () => {
							this.myeChart.resize();
						})
					})
				}

				if (this.LevelList.length > 3) {
					this.LevelScroll(0);
				}
			},

			LevelScroll(index) {
				this.$nextTick(() => {
					if (index == 0) {
						for (let i in this.LevelList) {
							document.getElementById("Level" + i).style.marginLeft = '0%';
						}
					} else {
						document.getElementById("Level" + (index - 1)).style.marginLeft = '-' + 33.3 * index + '%';
					}

					let number = index + 1;
					setTimeout(() => {
						if (number == (this.LevelList.length - 2)) {
							console.log(number)
							this.LevelScroll(0);
						} else {
							this.LevelScroll(number);
						}
					}, 5000);
				})
			},

			//问题清单
			getAuditTaskListInput() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "List-of-issues") {
						if (this.FakeData[f].isDockingData) {
							this.KanBanServiceProxy.getIssueList(

							).finally(() => {

							}).then(res => {
								this.AuditTaskList = res;

								this.AuditTaskList.map(item => {
									item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime)
										.format(
											'YYYY-MM-DD') : "";
									item.isDisplay = false;
									let nowDate = new Date();
									item.planEnd = item.planEnd ? moment(item.planEnd).format(
										'YYYY-MM-DD') : "";
									item.closeTime = item.closeTime ? moment(item.closeTime).format(
										'YYYY-MM-DD') : "";
									if (item.issueProgressName == '未完成') {
										if (item.planEnd) {
											if (Date.parse(item.planEnd) < Date.parse(nowDate)) {
												item.isDisplay = true;
											}
										}
									} else {
										if (item.planEnd) {
											if (Date.parse(item.planEnd) < Date.parse(item.closeTime)) {
												item.isDisplay = true;
											}
										}
									}
								});
								if (this.AuditTaskList.length != 0) {
									this.EndAuditTaskId = this.AuditTaskList[this.AuditTaskList.length - 1].id;
								}

								if (this.AuditTaskList.length > 2) {
									this.AuditTaskTimer = setInterval(this.AuditTaskScroll, 5000);
								} else if (this.AuditTaskList.length == 2) {
									clearInterval(this.AuditTaskTimer)
								} else if (this.AuditTaskList.length < 2) {
									for (let i = this.AuditTaskList.length; i < 2; i++) {
										let data = {
											discoveryTime: '',
											description: '',
											issueTypeListName: '',
											auditPoint: '',
											respName: '',
											issueProgressName: '',
											closeTime: '',
											isDisplay: false
										};
										this.AuditTaskList.push(data);
									}
									clearInterval(this.AuditTaskTimer)
								}
							})
						} else {
							this.AuditTaskList = JSON.parse(this.FakeData[f].value);

							this.AuditTaskList.map(item => {
								item.isDisplay = false;
								let nowDate = new Date();
								item.planEnd = item.planEnd ? moment(item.planEnd).format('YYYY-MM-DD') : "";
								item.closeTime = item.closeTime ? moment(item.closeTime).format('YYYY-MM-DD') : "";
								if (item.issueProgressName == '未完成') {
									if (item.planEnd) {
										if (Date.parse(item.planEnd) < Date.parse(nowDate)) {
											item.isDisplay = true;
										}
									}
								} else {
									if (item.planEnd) {
										if (Date.parse(item.planEnd) < Date.parse(item.closeTime)) {
											item.isDisplay = true;
										}
									}
								}
							});
							if (this.AuditTaskList.length != 0) {
								this.EndAuditTaskId = this.AuditTaskList[this.AuditTaskList.length - 1].id;
							}

							if (this.AuditTaskList.length > 2) {
								this.AuditTaskTimer = setInterval(this.AuditTaskScroll, 5000);
							} else if (this.AuditTaskList.length == 2) {
								clearInterval(this.AuditTaskTimer)
							} else if (this.AuditTaskList.length < 2) {
								for (let i = this.AuditTaskList.length; i < 2; i++) {
									let data = {
										discoveryTime: '',
										description: '',
										issueTypeListName: '',
										auditPoint: '',
										respName: '',
										issueProgressName: '',
										closeTime: '',
										isDisplay: false
									};
									this.AuditTaskList.push(data);
								}
								clearInterval(this.AuditTaskTimer)
							}
						}
					}
				}
			},

			AuditTaskScroll() {
				this.animate = true;
				setTimeout(() => {
					this.AuditTaskList.push(this.AuditTaskList[0]);
					this.AuditTaskList.shift();
					this.animate = false;
					if (this.AuditTaskList[0].id === this.EndAuditTaskId) {
						this.getAuditTaskListInput();
						clearInterval(this.AuditTaskTimer)
					}
				}, 500);
			},

			//问题区域TOP3
			getIusseRegion() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Issue-Area") {
						if (this.FakeData[f].isDockingData) {
							let date = new Date();
							this.KanBanServiceProxy.getIssuePointTopThree(
								date
							).finally(() => {

							}).then(res => {
								this.IssuePointName = [];
								this.IssuePointData = [];

								for (let i in res) {
									this.IssuePointName.push(res[i].name);
									this.IssuePointData.push(res[i].value);
								}

								this.IusseRegionCharts();
							})
						} else {
							let date = JSON.parse(this.FakeData[f].value);
							this.IssuePointName = [];
							this.IssuePointData = [];

							for (let i in date) {
								this.IssuePointName.push(date[i].areaName);
								this.IssuePointData.push(date[i].value);
							}

							this.IusseRegionCharts();
						}
					}
				}
			},

			IusseRegionCharts() {
				this.myeChart = echarts.init(document.getElementById("IusseRegion"));

				this.myeChart.clear();

				this.myeChart.setOption(this.option8);

				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//问题分类TOP3
			getIusseclassify() {
				for (let f in this.FakeData) {
					if (this.FakeData[f].code == "Issue-Type") {
						if (this.FakeData[f].isDockingData) {
							let date = new Date();
							this.KanBanServiceProxy.getIssueTypeTopThree(
								date
							).finally(() => {

							}).then(res => {
								this.IssueTypeData = res;

								this.IusseclassifyCharts();
							})
						} else {
							this.IssueTypeData = [];
							let IssueTypeData = JSON.parse(this.FakeData[f].value);
							for (let i in IssueTypeData) {
								let data = {};
								data.name = IssueTypeData[i].issueType;
								data.value = IssueTypeData[i].value;
								this.IssueTypeData.push(data);
							}

							this.IusseclassifyCharts();
						}
					}
				}
			},

			IusseclassifyCharts() {
				this.myeChart = echarts.init(document.getElementById("IusseClassify"));

				this.myeChart.clear();

				this.myeChart.setOption(this.option9);
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},

			//审核计划查看
			AuditPlanView(record) {
				if (record.id) {
					ModalHelper.create(AuditPlanView, {
						id: record.id
					}, {
						width: 1000
					}).subscribe(res => {

					})
				}
			},

			//审核任务列表
			AuditTaskView(record) {
				ModalHelper.create(AuditTaskView, {

				}, {
					width: 1200
				}).subscribe(res => {

				})
			},

			//问题查看
			IssueView(record) {
				if (record.id) {
					ModalHelper.create(IssueView, {
						id: record.id
					}, {
						width: 1000
					}).subscribe(res => {

					})
				}
			},

			//问题列表
			IssueListView(record) {
				ModalHelper.create(IssueListView, {

				}, {
					width: 1200
				}).subscribe(res => {

				})
			},

			KeyDown() {
				let width = document.getElementById('kanban').clientWidth;
				let height = document.getElementById('kanban').clientHeight;
				let screenWidth = window.screen.width;
				let screenHeight = window.screen.height;
				console.log(width)
				let left, top, scale;
				if ((screenWidth / screenHeight) > (width / height)) {
					scale = screenHeight / height;
					top = 0;
					left = (screenWidth - width * scale) / 2;
				} else {
					scale = screenWidth / width;
					top = 0;
					left = (screenHeight - height * scale) / 2;
				}
				document.getElementById('kanban').style.transform = 'scale(' + scale + ',' + scale + ')';
				document.getElementById('kanban').style.transformOrigin = 'top left';
			}
		}
	}
</script>

<style lang="less" scoped>
	.kanban {
		width: 1920px;
		height: 1080px;
		background-image: url(../../../../public/img/image/bj-bg.png);
		// background: #000f24;
	}

	.header {
		width: 100%;
		height: 12%;
		display: flex;
	}

	.title1 {
		width: 30%;
		height: 100%;
	}

	.title2 {
		width: 40%;
		height: 100%;
		color: #fff;
		font-size: 65px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.title3 {
		width: 30%;
		height: 100%;
		color: #fff;
		font-size: 25px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: right;
	}

	.mainstay {
		width: 100%;
		height: 88%;
		display: flex;
	}

	.row1 {
		width: 23.8%;
		height: 100%;
		margin-left: 2%;
	}

	.row2 {
		width: 46.5%;
		height: 100%;
		margin-left: 1%;
		margin-right: 1%;
	}

	.row3 {
		width: 23.8%;
		height: 100%;
		margin-right: 2%;
	}

	.subtitle {
		width: 100%;
		height: 5%;
		color: #fff;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.column1 {
		width: 100%;
		height: 31%;
		margin-bottom: 1%;
		// background-image: url(../../../../public/img/image/frame1.png);
	}

	.column2 {
		width: 100%;
		height: 48%;
		margin-bottom: 0.5%;
		// background-image: url(../../../../public/img/image/frame2.png);
	}

	.column3 {
		width: 100%;
		height: 22%;
		margin-bottom: 0.5%;
		// background-image: url(../../../../public/img/image/frame2.png);
	}

	.frame {
		width: 100%;
		height: 100%;
	}

	.layout {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.icon-outer {
		width: 18px;
		height: 18px;
		border-radius: 9px;
		border: 1px solid #42dfb7;
		position: absolute;
		left: 25px;
		top: 20px;
	}

	.icon-inner {
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-color: #42dfb7;
		top: 1.5px;
		left: 1.5px;
		position: absolute;
	}
	
	.auditPlanAnim {
		transition: all 0.5s;
		margin-top: -91px;
	}

	.anim {
		transition: all 0.5s;
		margin-top: -32px;
	}

	.auditTask {
		width: 94%;
		height: 75.5%;
		margin-left: 3%;
		overflow-y: auto;
		scrollbar-width: none;
		/* Firefox 64 */
	}

	.auditPlan {
		width: 94%;
		height: 80%;
		margin-left: 3%;
		overflow-y: auto;
		scrollbar-width: none;
		/* Firefox 64 */
	}

	.Level {
		width: 100%;
		height: 100%;
		overflow: hidden;
		white-space: nowrap;
	}

	.LevelAnim {
		transition: all 0.5s;
		margin-left: -32px;
	}

	.auditTask::-webkit-scrollbar {
		display: none;
	}

	.auditPlan::-webkit-scrollbar {
		display: none;
	}

	/* .Level::-webkit-scrollbar {
	      display: none;
	} */

	.backgroundcolor {
		color: #ff0000;
		background-color: #e957ee;
	}

	.planBox {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-image: url(../../../../public/img/image/box1.png);
	}

	.state1 {
		width: 61px;
		height: 32px;
		background-image: url(../../../../public/img/image/blue.png);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #afe1fd;
	}

	.state2 {
		width: 61px;
		height: 32px;
		background-image: url(../../../../public/img/image/green.png);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #a5e3c5;
	}

	.state3 {
		width: 61px;
		height: 32px;
		background-image: url(../../../../public/img/image/red.png);
		display: flex;
		align-items: center;
		justify-content: center;
		color: red;
	}

	.topBox {
		background-image: url(../../../../public/img/image/topBox.png);
		background-size: 100% 100%;
	}
	
	.list {
		width: 100%;height: 72%;padding: 7px;overflow-x: hidden;scrollbar-width: none;display: flex;flex-wrap: wrap;color: #fff;
	}
	
	.list .rowup {
					-webkit-animation: 30s rowup linear infinite normal;
					animation: 30s rowup linear infinite normal;
					position: relative;
				}
	
	@-webkit-keyframes rowup {
				0% {
					-webkit-transform: translate3d(0, 0, 0);
					transform: translate3d(0, 0, 0);
				}
 
				100% {
					-webkit-transform: translate3d(0, -200px, 0);
					transform: translate3d(0, -200px, 0);
				}
			}
 
			@keyframes rowup {
				0% {
					-webkit-transform: translate3d(0, 0, 0);
					transform: translate3d(0, 0, 0);
				}
 
				100% {
					-webkit-transform: translate3d(0, -200px, 0);
					transform: translate3d(0, -200px, 0);
				}
			}
</style>